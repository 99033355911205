export function buildListPath(basePath, request) {
    return basePath + '/comments?' + objectAsQuery(request);
}
export function buildBulkListPath(basePath) {
    return basePath + '/comments/bulk';
}
export function buildGetThreadPath(basePath, request) {
    return basePath + '/comments/thread?' + objectAsQuery(request);
}
export function buildQueryPath(basePath) {
    return basePath + '/comments/query';
}
export function buildGetCommentsPagePath(basePath, request) {
    return basePath + '/comments/page?' + objectAsQuery(request);
}
export function buildFollowMemberPath(basePath) {
    return basePath + '/comments/followMember';
}
export function buildCreateCommentPath(basePath) {
    return basePath + '/comments';
}
export function buildUpdateCommentPath(basePath) {
    return basePath + '/comments';
}
export function objectAsQuery(obj) {
    return Object
        .entries(obj)
        .reduce((acc, [field, value]) => {
        acc.push(...buildQueryRows(field, value));
        return acc;
    }, []).join('&');
}
function buildQueryRows(key, value, isArray = false) {
    if (typeof value === 'string' || typeof value === 'number') {
        const encodedValue = encodeURIComponent(value);
        return [`${key}=${encodedValue}`];
    }
    else if (Array.isArray(value)) {
        let index = 0;
        return value.reduce((acc, val) => {
            acc.push(...buildQueryRows(`${key}[${index++}]`, val, true));
            return acc;
        }, []);
    }
    else if (typeof value === 'object') {
        return Object.entries(value)
            .reduce((acc, [valueKey, innerValue]) => {
            const [prefix, postfix = ''] = isArray ? [key, ''] : key.split(/(]+)/);
            const newValueKey = `${prefix}[${valueKey}]${postfix}`;
            acc.push(...buildQueryRows(newValueKey, innerValue));
            return acc;
        }, []);
    }
    return [];
}
